<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="水表编号">
                <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="指令类型">
                <a-select v-model="queryParam.type" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.val">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="设置时间">
                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="userInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userName }}</span>
            <span>{{ record.userPhone }}</span>
          </div>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag color="red" v-if="record.status == 0">否</a-tag>
          <a-tag color="green" v-else-if="record.status == 1">是</a-tag>
        </span>
        <span slot="type" slot-scope="text, record">
          <span v-if="record.type == 0">周期</span>
          <span v-else-if="record.type == 1">结算日</span>
          <span v-else-if="record.type == 2">开关阀</span>
          <span v-else-if="record.type == 3">剩余水量</span>
          <span v-else-if="record.type == 4">更换表号</span>
          <span v-else-if="record.type == 5">设置表底数</span>
          <span v-else-if="record.type == 6">设置表类型</span>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { deviceSetInstructPage } from '@/api/pages/device'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Config',
  mixins: [tableMixin],
  dicts: ['sys_yes_no'],
  components: {
  },
  data () {
    return {
      list: [],
      loading: false,
      total: 0,
      typeList: [{
        name: '周期',
        val: 0
      }, {
        name: '结算日',
        val: 1
      }, {
        name: '开关阀',
        val: 2
      }, {
        name: '剩余水量',
        val: 3
      }, {
        name: '更换表号',
        val: 4
      }, {
        name: '设置表底数',
        val: 5
      }],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        type: undefined,
        devSn: null
      },
      columns: [
        {
          title: '水表编号',
          dataIndex: 'devSn',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          align: 'center'
        },
        {
          title: '指令类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '执行',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设置值',
          dataIndex: 'value',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 160,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设置时间',
          dataIndex: 'createTime',
          width: 160,
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      // console.log(this.dateRange)
      if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
        this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
        this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      }
      this.loading = true
      deviceSetInstructPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        devCode: null,
        devSn: null
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '提示',
        content: '是否确认导出?',
        onOk () {
          that.download('wisdom/wisdom-device-settle-record/export', {
            ...that.queryParam
          }, `设备上报明细_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
